.wrapper-class {
  height: 400px;
}

.editor {
  height: 300px;
  padding: 10px;
  border-radius: 3px;
  border: 1.5px solid #d9d9d9;
}
